/**
 * Create by zhuzhaoqing
 * Description: login
 * Date: 2023/3/31 10:12
 **/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 查询日志模块
export const queryTypeModule = () => request('/payAdminServer/operationLog/type/module', null, 'GET', null)

// 查询操作类型
export const queryOpsModule = () => request('/payAdminServer/operationLog/type/ops', null, 'GET', null)

// 查询日志列表
export const queryLogList = (data: {
  logType?: string
  tag?: string
  operatorName?: string
  pageNum: number
  pageSize: number
}) => request('/payAdminServer/operationLog/query', null, 'POST_JSON', data)

// 查询日志详情
export const queryLogDetail = (data: { id: string }) =>
  request('/payAdminServer/operationLog/get', data, 'GET')
